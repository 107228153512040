import { defineStyle, defineStyleConfig, theme } from "@chakra-ui/react";

/* base style */
const baseStyle = defineStyle({
    px: "2",
    py: "0.5",
    rounded: "md",
    fontSize: "xxs",
    fontWeight: "bold",
    letterSpacing: 0.5,
});

/* variants */
const solid = defineStyle({
    bg: "primary.500",
    color: "white",
});

const status = defineStyle(props => {
    const { colorScheme } = props;

    return {
        bg: `${colorScheme}.50`,
        color: `${colorScheme}.500`,
        borderColor: `${colorScheme}.500`,
        borderWidth: "1px",
    };
});

const green = defineStyle({
    px: "2",
    py: "1",
    fontWeight: "semibold",
    borderRadius: "md",
    border: "1px solid",
    borderColor: "alert.150",
    bg: "alert.300",
    color: "alert.150",
});

const orange = defineStyle({
    px: "2",
    py: "1",
    fontWeight: "semibold",
    borderRadius: "md",
    border: "1px solid",
    borderColor: "badge.250",
    bg: "alert.400",
    color: "badge.250",
});

const red = defineStyle({
    px: "2",
    py: "1",
    fontWeight: "semibold",
    borderRadius: "md",
    border: "1px solid",
    borderColor: "alert.100",
    bg: "alert.350",
    color: "alert.100",
});

const blue = defineStyle({
    px: "2",
    py: "1",
    fontWeight: "semibold",
    borderRadius: "md",
    border: "1px solid",
    borderColor: "detail.750",
    bg: "accent.650",
    color: "detail.750",
});

const white = defineStyle({
    px: "2",
    py: "2",
    borderRadius: "md",
    bg: "white",
    color: "grey",
});

const badgeTheme = defineStyleConfig({
    baseStyle,

    variants: {
        ...theme.components.Badge.variants,
        solid,
        green,
        orange,
        red,
        blue,
        white,
        status,
    },

    defaultProps: {
        variant: "solid",
    },
});

export default badgeTheme;
